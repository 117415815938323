import React from "react";

export default function FormGroupTextarea(props) {
	return (
		<div className={`form-group ${props.class}`}>
			<label htmlFor={props.id}>{props.label}</label>
			<textarea className={props.bg} id={props.id} name={props.id} required={props.required || false} />
		</div>
	);
}
