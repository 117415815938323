import React, {useEffect} from "react"
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import ContactSection from "../components/sections/contact-section";

const MerchPage = () => {
    useEffect(()=>{

        document.body.classList.add('merch-page');

        const bodyString = `<script>var spread_shop_config = {
                        shopName: 'sg-1online',
                        locale: 'de_EU',
                        prefix: 'https://shop.spreadshirt.net',
                        baseId: 'myShop'};</script>

                <script type="text/javascript"
                        src="https://shop.spreadshirt.net/shopfiles/shopclient/shopclient.nocache.js">
                </script>`

        var postscribe = require('postscribe');
        postscribe('#merch-content', bodyString);

    })
    return (
        <>
            <Helmet>
                <title>SG-1.online - Merch</title>

            </Helmet>
            <Layout>
                <main>
                    <div id={'merch-content'}>
                        <div id="myShop"/>
                    </div>
                </main>
                <ContactSection/>
            </Layout>
        </>
    )
}

export default MerchPage;
