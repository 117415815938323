import React from "react";

export function Button(props) {
	return (
		<button className={`button ${props.class}`}>
			{props.icon && (
				<svg className='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'>
					<path d='M.12 3.568v1.428h2.786v2.8h1.428v-2.8H7.12V3.568H4.334V.782H2.906v2.786z' fill='#currentColor' fillRule='nonzero' />
				</svg>
			)}
			<span className='filter' />
			<span className='label'>{props.label}</span>
		</button>
	);
}

export function FormButton(props) {
	return (
		<div className='mt-auto basis-auto md:mb-8'>
			<button type={props.type} className={`button mb-6 ${props.class}`} onClick={props.onPress}>
				{props.icon && (
					<svg className='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'>
						<path d='M.12 3.568v1.428h2.786v2.8h1.428v-2.8H7.12V3.568H4.334V.782H2.906v2.786z' fill='#currentColor' fillRule='nonzero' />
					</svg>
				)}
				<span className='filter' />
				<span className='label'>{props.label}</span>
			</button>
		</div>
	);
}
