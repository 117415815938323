import React from "react";
import { FormButton } from "../elements/button";
import FormGroup from "../elements/form-group";
import FormGroupTextarea from "../elements/form-group-textarea";
export default function ContactSection() {

	const handleClick = (e) => {
		// e.preventDefault();
		// alert("clicked");
	};

	return (
		<section className='contact-section' id={"contact"}>
			<div className='container'>
				<div className='flex flex-wrap'>
					<div className='basis-full'>
						<h1 className='h1 gradient-text'>Start your journey with us</h1>
					</div>
					<div className='basis-full'>
						<form className='contact-form' method={"post"} action={"https://getform.io/f/b69961c9-eb3a-40fa-8cfe-944a694edcdf"}>
							<div className={"grid grid-cols-1 md:grid-cols-2 md:gap-x-10 lg:grid-cols-3 lg:gap-x-16"}>
								<FormGroup id={"name"} label={"Your Name"} type={"text"} bg={`bg`} required={true} />
								<FormGroup id={"email"} label={"Email"} type={"email"} bg={`bg`} required={true} />
								<FormGroup id={"subject"} label={"Subject"} bg={`bg`} required={true} />
								<FormGroupTextarea id={"message"} label={"Message"} class={"md:col-span-2"} bg={`bg`} required={true} />
								<FormButton type='submit' class='secondary' label='Send Message' onPress={handleClick} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}
