import React from "react"

export default function FormGroup(props) {
    return (
        <div className={`form-group ${props.class}`}>
        <label htmlFor={props.id}>{props.label}</label>
        <input className={props.bg} type={props.type || 'text'} id={props.id} name={props.id}
        required={props.required || false}
        />
    </div>
    )
}